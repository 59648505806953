import React, { Component } from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

export const query = graphql`
  fragment Meta on MarkdownRemark {
    frontmatter {
      template
      date
      # cover
      categories {
          category
      }
      featuredImage{
              childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                  }
              }
            }
      meta {
        title
        description
        noindex
        canonicalLink
        keywords
      }
    }
  }
`


export default class Meta extends Component {
  render() {
    const {
      category,
      featuredImage,
      datePosted,
      dateModified,
      template,
      title,
      siteUrl,
      url,
      description,
      absoluteImageUrl = '',
      twitterSiteAccount,
      twitterCreatorAccount,
      noindex,
      canonicalLink,
      siteTitle,
      // siteDescription,
      googleTrackingId,
      keywords,
      // cover,
      // author= 'Saki Hayashi',
      siteLanguage= 'ja',
      // buildTime= '2019-12-03',
      // overwrite { title, description } if in fields or fields.meta
    } = this.props

 
  let schemaHomePage = null

  if(template === 'HomePage'){
    schemaHomePage ={
      "@context":"https://schema.org",
      "@graph":
      [
          {
              "@type":"WebSite",
              "@id": canonicalLink + "#website",
              "url": siteUrl,
              "name":"Flamingod News",
              "potentialAction":{
                  "@type":"SearchAction",
                  "target":"https://flamingod.com/?s={search_term_string}",
                  "query-input":"required name=search_term_string"
                  }
          },{
              "@type":"WebPage",
              "@id": canonicalLink + "#webpage",
              "url": siteUrl,
              "inLanguage": siteLanguage,
              "name": title,
              "isPartOf":{"@id": canonicalLink + "#website"},
              "datePublished": datePosted,
              "dateModified": dateModified,
              "description": description
              }
      ]
    }
  }
  
let schemaBlogs = null;

if(template === 'SinglePost'){
  var urlArry = canonicalLink.split('/')
  var blogCategory = urlArry.slice(0, urlArry.length-2).join('/')
  schemaBlogs = {
    "@context":"https://schema.org",
    "@graph":[
        {
            "@type":"Organization",
            "@id":　siteUrl + "/#organization",
            "name":"Flamingod News",
            "url": siteUrl,
            "sameAs":["https://twitter.com/FlamingodN"],
            "logo":{"@type":"ImageObject",
            "@id": siteUrl + "/#logo",
            "url":"../../static/images/flamingod-logo_120x120.png",
            "width":120,"height":120,
            "caption":"Flamingod News"},
            "image":{"@id":"https://flamingod.com/#logo"},
            "legalName":"Flamingod News by MusicofSnow, LLC"
        },{
            "@type":"WebSite",
            "@id":"https://flamingod.com/#website",
            "url":"https://flamingod.com/",
            "name":"Flamingod News",
            "description":"アメリカから最新のITニュースを届けます",
            "publisher":{"@id":"https://flamingod.com/#organization"},
            "potentialAction":{
                "@type":"SearchAction",
                "target":"https://flamingod.com/?s={search_term_string}",
                "query-input":"required name=search_term_string"}
        },{
            "@type":"ImageObject",
            "@id": canonicalLink + "#primaryimage",
            "url": featuredImage.childImageSharp.fluid.src,
            "width":1000,
            // "height":628,
            "caption": title
        },{
            "@type":["WebPage"],
            "@id": canonicalLink + "/#webpage",
            "url": canonicalLink,
            "inLanguage": siteLanguage,
            "name": title,
            "isPartOf":{"@id":"https://flamingod.com/#website"},
            "primaryImageOfPage":{"@id": canonicalLink + "#primaryimage"},
            "datePublished": datePosted,
            "dateModified": dateModified,
            "description": description,
            "breadcrumb":{
                "@id": canonicalLink + "#breadcrumb"}
        },{
            "@type":"BreadcrumbList",
            "@id": canonicalLink + "#breadcrumb",
            "itemListElement":[
            {
                "@type":"ListItem",
                "position":1,
                "item":{"@type":"WebPage",
                "@id": siteUrl + "/",
                "url": siteUrl + "/",
                "name":"Home"}
            },{
                "@type":"ListItem",
                "position":2,
                "item":{
                    "@type":"WebPage",
                    "@id": siteUrl + "/blog/",
                    "url": siteUrl + "/blog/",
                    "name":"アメリカでは常識の最新IT情報ブログ"}
            },{
                "@type":"ListItem",
                "position":3,
                "item":{
                    "@type":"WebPage",
                    "@id":　blogCategory,
                    "url":　blogCategory,
                    "name": category
                    }
            },{
                "@type":"ListItem",
                "position":4,
                "item":{
                    "@type":"WebPage",
                    "@id": canonicalLink,
                    "url": canonicalLink,
                    "name": title
                    }
            }]
        },{
            "@type":"Article",
            "@id": canonicalLink + "#article",
            "isPartOf":{"@id": canonicalLink + "#webpage"},
            "author":{"@id":"https://flamingod.com/about/"},
            "headline": title,
            "datePublished": datePosted,
            "dateModified": dateModified,
            "mainEntityOfPage":{"@id": canonicalLink + "#webpage"},
            "publisher":{"@id":"https://flamingod.com/#organization"},
            "image":{"@id": canonicalLink + "#primaryimage"},
            "keywords": keywords
        },{
            "@type":["Person"],
            "@id": "https://flamingod.com/about/",
            "name":"Saki Hayashi",
            "image":{
                "@type":"ImageObject",
                "@id":"https://flamingod.com/#authorlogo",
                "url":"../../static/images/saki_hayashi.jpg",
                "caption": "Saki Hayashi"
                },
            "description":"Saki Hayashi is a 2D and 3D UX/UI designer, creative technologist, architect, full-stack web developer, and artist who brings art, design and technology together in order to create better space for humanity.",
            "sameAs":["https://www.facebook.com/saki.ichikawa","https://www.linkedin.com/in/saki-hayashi-91638218","https://twitter.com/snowy_saki","https://www.sakihayashi.info"]
        }]
    }
}
  
let schemaBlogIndex = null;
var breadcrumbsList = [];

if(template === 'BlogIndex'){
  var str = canonicalLink.split('/')
  
  if(str){
    breadcrumbsList = [
      {
      "@type":"ListItem",
                "position":1,
                "item":{
                  "@type":"WebPage",
                  "@id": siteUrl + "/",
                  "url": siteUrl + "/",
                  "name":"Home"}
    },{
      "@type":"ListItem",
                "position":2,
                "item":{
                  "@type":"WebPage",
                  "@id": siteUrl + "/blog/",
                  "url": siteUrl + "/blog/",
                  "name":"Blog Index for all blogs"}
    }
  ]
  
  if(str.length === 6){
    breadcrumbsList.push(
      {
        "@type":"ListItem",
                  "position":3,
                  "item":{
                    "@type":"WebPage",
                    "@id": canonicalLink,
                    "url": canonicalLink,
                    "name": title
                  }
      }
    )    
  }

  }
  
  // var blogCategory = urlArry.slice(0, urlArry.length-2).join('/')
  schemaBlogIndex =   {
    "@context":"https://schema.org",
    "@graph":[
        {
        "@type":"Organization",
        "@id": siteUrl + "/#organization",
        "name":"Flamingod News",
        "url":"https://flamingod.com/",
        "sameAs":["https://twitter.com/FlamingodN"],
        "logo":{
            "@type":"ImageObject",
            "@id": siteUrl + "/#logo",
            "url": "../../static/images/flamingod-logo_120x120.png",
            "width":120,
            "height":120,
            "caption":"Flamingod News"
            },
        "image":{"@id":"https://flamingod.com/#logo"},
        "legalName":"Flamingod News by MusicofSnow, LLC"
      },{
          "@type":"WebSite",
          "@id": siteUrl + "/#website",
          "url": siteUrl,
          "name": "Flamingod News",
          "description": description + "|Flamingod News",
          "publisher":{"@id": siteUrl + "/#organization"},
          "potentialAction":{
              "@type":"SearchAction",
              "target":"https://flamingod.com/?s={search_term_string}",
              "query-input":"required name=search_term_string"}
      },{
          "@type":["CollectionPage"],
          "@id": canonicalLink + "#webpage",
          "url": canonicalLink,
          "inLanguage": siteLanguage,
          "name": title,
          "isPartOf":{"@id": siteUrl + "/#website"},
          "description": description,
          "breadcrumb":{"@id": canonicalLink + "#breadcrumb"}
      },{
          "@type":"BreadcrumbList",
          "@id": canonicalLink + "#breadcrumb",
          "itemListElement": breadcrumbsList
      }
  ]}

}

    return (
      <Helmet>
        {title && <title>{title}</title>}
        {title && <meta property="og:title" content={title} />}
        {description && <meta name="description" content={description} />}
        {description && (
          <meta property="og:description" content={description} />
        )}
        {url && <meta property="og:type" content="website" />}
        {url && <meta property="og:url" content={url} />}
        {twitterSiteAccount && (
          <meta name="twitter:site" content={twitterSiteAccount} />
        )}
        {twitterCreatorAccount && (
          <meta name="twitter:creator" content={twitterCreatorAccount} />
        )}
        {noindex && <meta name="robots" content="noindex" />}
        {canonicalLink && <link rel="canonical" href={canonicalLink} />}

        <meta property="og:locale" content="ja" />
        <meta property="og:site_name" content={siteTitle} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:title" content={siteTitle} />
        <meta name="twitter:image" content={absoluteImageUrl} />
        <meta property="og:image:secure_url" content={absoluteImageUrl} />
        <meta property="og:image" content={absoluteImageUrl} />
        <meta name="twitter:card" content={absoluteImageUrl} />
        {/* <meta name="yandex-verification" content="4ea50121d0db5365" /> */}


        <meta name="keywords" content={keywords} />
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8"></meta>

        {googleTrackingId && (
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${googleTrackingId}`}
          />
        )}

        {googleTrackingId && (
          <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${googleTrackingId}');
            `}
          </script>
        )}
        {/* Insert schema.org data conditionally (webpage/article) + everytime (breadcrumbs) */}
        {/* {template === 'ComponentsPage'? <script type="application/ld+json">{JSON.stringify(schemaOrgWebPage)}</script> : ''} */}
        {template === 'SinglePost' ? <script type="application/ld+json">{JSON.stringify(schemaBlogs)}</script> : ''}
        {template === 'HomePage' ? <script type="application/ld+json">{JSON.stringify(schemaHomePage)}</script> : '' }
        {template === 'BlogIndex' ? <script type="application/ld+json">{JSON.stringify(schemaBlogIndex)}</script> : '' }
        {/* {!schemaArticle && <script type="application/ld+json">{JSON.stringify(schemaOrgWebPage)}</script>}
        {schemaArticle && <script type="application/ld+json">{JSON.stringify(schemaArticle)}</script>} */}
      </Helmet>
      
    )
  }
}

import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import Meta from './Meta'
import Nav from './Nav'
import Footer from './Footer'

import 'modern-normalize/modern-normalize.css'
import './globalStyles.css'

export default ({ children, meta, title, template, datePosted, dateModified, featuredImage, slug, category}) => {
  return (
    <StaticQuery
      query={graphql`
        query IndexLayoutQuery {
          settingsYaml {
            siteTitle
            siteDescription
            googleTrackingId
            siteUrl
            socialMediaCard {
              image
            }
          }
          allPosts: allMarkdownRemark(
            filter: { fields: { contentType: { eq: "postCategories" } } }
            sort: { order: DESC, fields: [frontmatter___date] }
          ) {
            edges {
              node {
                fields {
                  slug
                }
                frontmatter {
                  title
                }
              }
            }
          }
          allMarkdown: allMarkdownRemark {
            nodes {
              frontmatter {
                template
                date
                featuredImage {
                  childImageSharp {
                    fluid {
                      src
                    }
                  }
                }
                title
              }
            }
          }
        }
      `}
      
      render={data => {
        const { siteTitle, socialMediaCard, googleTrackingId } =
            data.settingsYaml || {},
          subNav = {
            posts: data.allPosts.hasOwnProperty('edges')
              ? data.allPosts.edges.map(post => {
                  return { ...post.node.fields, ...post.node.frontmatter }
                })
              : false
          }


        return (
          <Fragment>
            <Helmet
              defaultTitle={siteTitle}
              titleTemplate={`%s | ${siteTitle}`}
            >
              {title}
              {/* <link href="https://ucarecdn.com" rel="preconnect" crossorigin /> */}
              <link rel="dns-prefetch" href="https://ucarecdn.com" />
              <link href="https://fonts.googleapis.com/css?family=Alegreya+Sans|Economica|Martel|Orbitron:500&display=swap" rel="stylesheet" />
              {/* Add font link tags here */}
        
            </Helmet>

            <Meta
              googleTrackingId={googleTrackingId}
              absoluteImageUrl={
                socialMediaCard &&
                socialMediaCard.image &&
                socialMediaCard.image
              }
              {...meta}
              {...data.settingsYaml}
              template = {template}
              datePosted = {datePosted}
              featuredImage = {featuredImage || false}
              slug = {slug}
              category = {category}
              dateModified = {dateModified}
            />

            <Nav subNav={subNav} />

            <Fragment>{children}</Fragment>
            
            <Footer />
     
          </Fragment>
        )
      }}
    />
  )
}
